import { computed, reactive } from 'vue';
import { ITelaOperacao } from '../models/ITelaOperacao';
import { IRetornoRequisicao } from '../models/IRetornoRequisicao';

export interface IModalBase {
  textoBotaoSalvar: string;
  textoBotaoSalvarNovo: string;
  apresentarBarraProgresso: boolean;
  fraseBarraProgresso: string;
  exibirRequisicaoModal: boolean;
  retornoRequisicao: IRetornoRequisicao;
  computedOperacao: ITelaOperacao;
  computedVisivel: boolean;
}
export interface IRetornoModalBase {
  modalBase: IModalBase;
  defineNovaOperacao: (telaOperacao: ITelaOperacao) => void;
  defineTextoPadraoBotoes: (incluir: boolean, expressaoNova?: boolean) => void;
  apresentarBarraProgresso: (frase?: string) => void;
  ocultarBarraProgresso: () => void;
  apresentarRetornoRequisicao: (retornoRequisicao: IRetornoRequisicao) => void;
  sincronizarRegistro: (codigoRegistro: number) => void;
}
export function useModalBase(props: any, emit: any): IRetornoModalBase {
  const modalBase = reactive({
    textoBotaoSalvar: 'Concluir cadastro',
    textoBotaoSalvarNovo: 'Concluir e novo',
    apresentarBarraProgresso: false,
    fraseBarraProgresso: '',
    exibirRequisicaoModal: false,
    retornoRequisicao: {} as IRetornoRequisicao,
    computedOperacao: computed({
      get: () => props.telaGenerica,
      set: (val: ITelaOperacao) => {
        emit('update:operacao', val);
      },
    }),
    computedVisivel: computed({
      get: () => props.visivel,
      set: (val: boolean) => {
        emit('update:visivel', val);
      },
    }),
  });

  function defineNovaOperacao(telaOperacao: ITelaOperacao) {
    const novaOperacao: ITelaOperacao = telaOperacao;
    novaOperacao.codigoRegistro = 0;
    modalBase.computedOperacao = novaOperacao;
  }

  function defineTextoPadraoBotoes(incluir: boolean, expressaoNova?: boolean) {
    if (incluir) {
      modalBase.textoBotaoSalvar = 'Concluir cadastro';
      modalBase.textoBotaoSalvarNovo = expressaoNova ? 'Concluir e nova' : 'Concluir e novo';
    } else {
      modalBase.textoBotaoSalvar = 'Salvar alterações';
      modalBase.textoBotaoSalvarNovo = expressaoNova ? 'Salvar e nova' : 'Salvar e novo';
    }
  }

  function sincronizarRegistro(codigoRegistro: number) {
    emit('sincronizarRegistro', codigoRegistro);
  }

  function apresentarBarraProgresso(frase?: string) {
    modalBase.apresentarBarraProgresso = true;
    modalBase.exibirRequisicaoModal = true;
    modalBase.fraseBarraProgresso = frase === undefined ? '' : frase;
  }

  function ocultarBarraProgresso() {
    modalBase.exibirRequisicaoModal = false;
    modalBase.apresentarBarraProgresso = false;
    modalBase.fraseBarraProgresso = '';
  }

  function apresentarRetornoRequisicao(retornoRequisicao: IRetornoRequisicao) {
    modalBase.apresentarBarraProgresso = false;
    modalBase.fraseBarraProgresso = '';
    modalBase.exibirRequisicaoModal = true;
    modalBase.retornoRequisicao = retornoRequisicao;
  }

  return {
    modalBase,
    defineNovaOperacao,
    defineTextoPadraoBotoes,
    apresentarBarraProgresso,
    ocultarBarraProgresso,
    apresentarRetornoRequisicao,
    sincronizarRegistro,
  };
}
